import { Button } from 'react-component-library-ts';

export const Home = () => {
    return (
        <div className="h-full w-full">
            <main className="h-full w-full flex flex-col justify-evenly items-center">
                <span className="text-base font-semibold test-slate-800">Hello Ji</span>
                <Button />
            </main>
        </div>
    );
};
